<template>
<router-view :key="$route.fullPath"/>
</template>
<script>

export default {
  name: 'App',
  components: {
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  font-family: cursive;
}
body{
  margin: 0;
  /* background-color: #f7f7f7; */
}
.header1 {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
}
.article-main{
  max-height: 500px;
}
</style>
